import classes from './Modal.module.css';
import { createPortal} from 'react-dom';

const BackDrop = (props) => {
    return <div className={classes.backdrop} onClick={props.onhide}/>;
};

const Overlay = (props) => {
    return (
        <div className={classes.overlay}>
            <div className={classes.content}>{props.children}</div>
        </div>
    )
};

const portalNode = document.getElementById('overlays');

const Modal = (props) => {
    return (
        <>
            {createPortal(<BackDrop onhide={props.hideProduct} />, portalNode)}
            {createPortal(<Overlay>{props.children}</Overlay>, portalNode)}
        </>
    )
};

export default Modal;