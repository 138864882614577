import { useState, useEffect } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import classes from './DetailList.module.css';

const DetailsList = (props) => {
    const [addDets, setAddDets] = useState(null);

    const addDetailsHandler = (jsx) => {
        setAddDets(jsx)
    }

    useEffect(() => {
        if (props.dets.sn == '9810029') {
            const jsx = (<><li>Factory New!</li><li>Fiber Optic</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '021') {
            const jsx = (<><li>Factory New!</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '1609260017') {
            const jsx = (<><li>Refurbished turbine with U.S. &amp; German parts</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '01700508200' || props.dets.sn == '0190125819') {
            const jsx = (<><li>Push Button</li><li>Fiber Optic</li><li>Air Generated</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == 'P54032') {
            const jsx = (<><li>A.C. High Speed Dental Surgery Handpiece</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == 'P3C766') {
            const jsx = (<><li>A.C. High Speed Dental Surgery Handpiece</li><li>All New German Bearings</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '3942') {
            const jsx = (
                <>
                    <li>New Turbine with German Bearings</li>
                    <ul>
                        <li>ABEC 7 grade Bearings</li>
                    </ul>
                    <li>New PB cap</li>
                </>
            );
            addDetailsHandler(jsx);
        } else if (props.dets.sn == 'N295489') {
            const jsx = (<><li>Multiflex Lux Coupler</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.name == 'LARES SET OF 3 HSHP') {
            const jsx = (
                <>
                    <li>Sold As a Set</li>
                    <li>Quick Disconnect Coupler</li>
                    <li>Serial Numbers:</li>
                    <ul>
                        <li>Pedodontic - 842229</li>
                        <li>Pedodontic - 969526</li>
                        <li>Super Torque - 952428</li>
                        <li>Coupler - 345923</li>
                    </ul>
                </>
            );
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '117361' || props.dets.sn == '117412' || props.dets.sn == '117414') {
            const jsx = (<><li>Precision Torque</li><li>Fiber Optic</li><li>Auto Chuck</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '281541A') {
            const jsx = (<><li>New Turbine</li><li>Cap Tests Clinic Grade</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '24062703' || props.dets.sn == '24035738') {
            const jsx = (<><li>AC Fiber Optic</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '52157137' || props.dets.sn == '52021566') {
            const jsx = (<><li>AC Fiber Optic</li><li>90% Fiber Optic Translucency</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '52157233') {
            const jsx = (<><li>AC Fiber Optic</li><li>85% Fiber Optic Translucency</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '52255664') {
            const jsx = (<><li>AC Fiber Optic</li><li>95% Fiber Optic Translucency</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == 'B4804714') {
            const jsx = (
                <>
                    <li>1:5 Electric High Speed</li>
                    <li>New Head Turbine</li>
                    <li>Very Low Noise</li>
                    <li>No Vibration</li>
                    <li>Clear Fiber Optic</li>
                </>
            );
            addDetailsHandler(jsx);
        } else if (props.dets.sn == 'A10169' || props.dets.sn == 'C11436' || props.dets.sn == 'P01469C4') {
            const jsx = (<><li>Quick Disconnect</li><li>Fiber Optic</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '08-1005352') {
            const jsx = (<><li>Swivel AC Fiber Optic</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == 'F1D123') {
            const jsx = (<><li>Ball Bearing Turbine</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == 'F1D123' || props.dets.sn == '0021127') {
            const jsx = (<><li>Ball Bearing Turbine</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '15010379') {
            const jsx = (<><li>Cleaned</li><li>Lubricated</li><li>New Drive Dog</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.id == 'F1D2') {
            const jsx = (
                <>
                    <li>Rhino with Nose Cone</li>
                    <li>2 hole or 4</li>
                    <li>Serial Numbers:</li>
                    <ul>
                        <li>Rhino - F1D2</li>
                        <li>Nose Cone - D 8254</li>
                    </ul>
                </>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == 'FIDI') {
            const jsx = (<><li>Latch Head</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.name == 'STAR DENTAL') {
            const jsx = (<><li>Swivel Quick Disconnect</li><li>No Fiber Optics</li><li>No Water Coolant</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '101312' || props.dets.sn == '75550' || props.dets.sn == '85706' || props.dets.sn == '34854') {
            const jsx = (<><li>Low Speed Motor</li><li>5000rpm</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '101312' || props.dets.sn == '75550' || props.dets.sn == '85706' || props.dets.sn == '34854') {
            const jsx = (<><li>Low Speed Motor</li><li>5000rpm</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.name == 'STAR LATCH HEAD – NEW') {
            const jsx = (
                <>
                    <li>Ball Bearing Turbine</li>
                    <li>Reconditioned Adaptor</li>
                    <li>Serial Numbers:</li>
                    <ul>
                        <li>Head - 92588</li>
                        <li>Adaptor - 707647</li>
                    </ul>
                </>
            );
            addDetailsHandler(jsx);
        } else if (props.dets.sn == '13F0018') {
            const jsx = (<><li>Brand New</li></>);
            addDetailsHandler(jsx);
        } else if (props.dets.name == 'STAR TITAN SWIVEL 20K AIR MOTORS & STAR DENTAL QUICK DISCONNECT') {
            const jsx = (
                <>
                    <li>Sold As a Set</li>
                    <li>Air Motor #1:</li>
                    <ul>
                        <li>Five New Turbine Vanes</li>
                        <li>Individual Price - $480</li>
                    </ul>
                    <li>Air Motor #2</li>
                    <ul>
                        <li>Individual Price - $470</li>
                    </ul>
                    <li>Quick Disconnect</li>
                    <li>Swivel Low Speed</li>
                    <ul>
                        <li>Individual price - $115</li>
                    </ul>
                    <li>Serial Numbers:</li>
                    <ul>
                        <li>Air Motor #1 - 042597</li>
                        <li>Air Motor #2 - 094080</li>
                        <li>Quick Disconnect - G01432</li>
                    </ul>
                </>
            );
            addDetailsHandler(jsx);
        }
    }, [])
    
    return (
        <div className={classes.ul_section}>
            <ul className={classes.unordered_lst}>
                { props.dets.org_price && <li>Schein Price Comparison: ${props.dets.org_price} </li> }
                { props.dets.schein_num && <li>Schein Number: {props.dets.schein_num}</li>}
                { addDets }
                <li>Warranty Included</li>
                <li>Tax Included in Price</li>
                <li>Clinic Grade</li>
                { props.dets.sn && <li>Serial Number: {props.dets.sn}</li> }
                { props.dets.sns && <li></li>}
            </ul>
        </div>
    )
}

export default DetailsList;