import { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom'; 
import { Access } from '../config/access';

import HighSpeeds from '../components/forsale/HighSpeeds';
import LowSpeeds from '../components/forsale/LowSpeeds';
import products from '../static/products';
import ProductDetails from '../components/product_details/ProductDetails';

const ForSalePage = () => {
    const [detailsShown, setDetailsShown] = useState(false);
    const [currentProduct, setCurrentProduct] = useState('');
    const [productDetails, setProductDetails] = useState(null);
    const response = useLoaderData();

    const showDetailsHandler = (info) => {
        setDetailsShown(true);
        setCurrentProduct(info);
        setProductDetails(response);
    };
    
    const hideDetailsHandler = () => {
        setDetailsShown(false);
    };
    
    const high_speeds = products.highSpeeds;
    const low_speeds = products.lowSpeeds;

    return (
        <>
            {detailsShown && 
                <ProductDetails 
                    onVanish={hideDetailsHandler} 
                    current={currentProduct} 
                    details={productDetails}
                />}
            <HighSpeeds hsProductData={high_speeds}  onAppear={showDetailsHandler} />
            <LowSpeeds lsProductData={low_speeds} onAppear={showDetailsHandler} />
        </>
    )
};

export default ForSalePage;

export const loader = async () => {
    Access();
    const url = 'https://fidelity-dental-handpiec-8a3e7-default-rtdb.firebaseio.com/.json';
    const response = fetch(url)
    .then(response => response.json())
    .catch(error => console.error('Error fetching data:', error));
    
    return response; 
}