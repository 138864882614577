import { useState } from 'react';
import classes from './OrderForm.module.css';
import useValidation from '../../custom hooks/useValidation';

const emptyCheck = (value) => { return value.trim() !== '' };

const OrderForm = (props) => {
    const [countryCode, setCountryCode] = useState('+1');

    const {
        value: firstName,
        inputIsValid: firstNameValid,
        inputHasError: firstNameError,
        inputValueHandler: firstNameHandler,
        inputBlurHandler: firstNameBlur,
        inputReset: firstNameReset,
    } = useValidation(emptyCheck);
    
    const {
        value: lastName,
        inputIsValid: lastNameValid,
        inputHasError: lastNameError,
        inputValueHandler: lastNameHandler,
        inputBlurHandler: lastNameBlur,
        inputReset: lastNameReset,
    } = useValidation(emptyCheck);
    
    const {
        value: phoneNumber,
        inputIsValid: phoneNumberValid,
        inputHasError: phoneNumberError,
        inputValueHandler: phoneNumberHandler,
        inputBlurHandler: phoneNumberBlur,
        inputReset: phoneNumberReset,
    } = useValidation((phoneNumber) => {
        const numIsValid = new RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/, '');
        return numIsValid.test(phoneNumber.trim());
    });

    const {
        value: location,
        inputIsValid: locationValid,
        inputHasError: locationError,
        inputValueHandler: locationHandler,
        inputBlurHandler: locationBlur,
        inputReset: locationReset,
    } = useValidation(emptyCheck);

    const {
        value: email,
        inputIsValid: emailValid,
        inputHasError: emailError,
        inputValueHandler: emailHandler,
        inputBlurHandler: emailBlur,
        inputReset: emailReset,
    } = useValidation((email) => {
        const emailIsValid = 
            new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, '');
        return emailIsValid.test(email.trim());
    });

    // Closing form action
    const cancelFormHandler = () => {
        props.cancelForm();
    };

    let formIsValid = false;

    formIsValid = firstNameValid && lastNameValid && phoneNumberValid && locationValid && emailValid;


    // Placing order!!!
    const submitHandler = (event) => {
        event.preventDefault();
        
        if (!formIsValid) {
            firstNameBlur();
            lastNameBlur();
            phoneNumberBlur();
            locationBlur();
            emailBlur();
            return
        }
        const customerInfo = {
            firstName: firstName, 
            lastName: lastName,
            phoneNumber: phoneNumber,
            location: location,
            email: email,
            productName: props.dets.name,
            productSN: props.dets.sn,
            productPrice: `$${props.dets.sale_price}`,
        };
        console.log(customerInfo);
        
        firstNameReset();
        lastNameReset();
        phoneNumberReset();
        locationReset();
        emailReset();
    };

    const codeObject = require('../../static/countryCodes.json');
    const countryCodes = codeObject.countries;

    const countryCodeHandler = (e) => {
        const selectedCountry = e.target.value;
        countryCodes.map((cc) => {
            if(cc.name === selectedCountry) {
                setCountryCode(cc.code);
            }
        })
    };

    const firstNameClass = !firstNameError ? classes.form_field : `${classes.form_field} ${classes.input_error}`;
    const lastNameClass = !lastNameError ? classes.form_field : `${classes.form_field} ${classes.input_error}`;
    const phoneNumberClass = !phoneNumberError ? classes.form_field : `${classes.form_field} ${classes.input_error}`;
    const locationClass = !locationError ? classes.form_field : `${classes.form_field} ${classes.input_error}`;
    const emailClass = !emailError ? classes.form_field : `${classes.form_field} ${classes.input_error}`;
    
    return (
        <form action='submit' onSubmit={submitHandler} className={classes.form}>
            <input 
                className={firstNameClass}
                type='text' 
                value={firstName}
                placeHolder='First Name'
                onBlur={firstNameBlur}
                onChange={firstNameHandler}
            />
            <div className={classes.error_message}>{firstNameError && <>Please provide your first name</>}</div>
            <input
                className={lastNameClass}
                type='text' 
                value={lastName}
                placeHolder='Last Name' 
                onBlur={lastNameBlur}
                onChange={lastNameHandler} 
            />
            <div className={classes.error_message}>{lastNameError && <>Please provide your last name</>}</div>
            <select name="Country Code" className={classes.countries} onChange={countryCodeHandler}>
                {countryCodes.map((cc) => {
                    const countryName = cc.name;
                    const option = cc.name === 'United States' ? <option value={countryName} selected>{countryName}</option> : <option value={countryName}>{countryName}</option>
                    return option;
                })}
            </select>
            <div className={classes.cel_section}>
                <span className={classes.country_code}>{countryCode}</span>
                <input 
                    className={phoneNumberClass}
                    type='tel'
                    value={phoneNumber}
                    placeHolder='Phone Number' 
                    onBlur={phoneNumberBlur}
                    onChange={phoneNumberHandler} 
                />
            </div>
            <div className={classes.error_message}>{phoneNumberError && <>Please provide a valid phone number</>}</div>
            <input 
                className={locationClass}
                type='text' 
                value={location}
                placeHolder='Dental School/Office'
                onBlur={locationBlur}
                onChange={locationHandler} 
            />
            <div className={classes.error_message}>{locationError && <>Please provide your dental school or office</>}</div>
            <input 
                className={emailClass}
                type='email'
                value={email}
                placeHolder='Email'
                onBlur={emailBlur}
                onChange={emailHandler}
            />
            <div className={classes.error_message}>{emailError && <>Please provide a valid email address</>}</div>
            <div className={classes.buttons}>
                <button className={classes.order_button} type='submit'>Place Order</button>
                <button className={classes.order_button} onClick={cancelFormHandler}>Cancel</button>
            </div>
        </form>
    )
};

export default OrderForm;