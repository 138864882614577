import { Outlet, useLocation } from 'react-router-dom';
import Nav from '../components/navigation/Nav';
import Footer from '../components/footer/Footer';

const RootLayout = () => {
    const url = useLocation()

    const onDetailsPage = url.pathname.includes('p');

    return (
        <> 
            <Nav />
            <Outlet />
            {!onDetailsPage && <Footer />}
        </>
    );
}

export default RootLayout;