import Carousel from '../components/home/Carousel';
import GeneralInfo from '../components/home/GeneralInfo';
import Warranty from '../components/warranty/Warranty';

const HomePage = () => {
    return (
        <div>
            <Carousel />
            <GeneralInfo />
        </div>
    )
};

export default HomePage;
