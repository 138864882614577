import classes from './StoreItem.module.css';

const StoreItem = (props) => {

    const ViewProductHandler = () => {
        const product_info = [props.item.id, props.item.high_speed];
        props.viewProduct(product_info);
    };

    const view_product = props.item.sold ? <button className={classes.view_disabled} onClick={ViewProductHandler} disabled>View Product</button> : <button className={classes.view_botton} onClick={ViewProductHandler}>View Product</button>


    return (
        <div key={props.item.id} className={classes.box}>
            <div className={classes.contain}>
                <div className={classes.img_box}>
                    <img src={props.item.tn_img} alt={`${props.item.name} img`} />
                </div>
                <div className={classes.details}>
                    <div className={classes.info}>
                        <h5 className={classes.titles}>{props.item.name}</h5>
                        <p className={classes.prices}>${props.item.sale_price}<span className={classes.old_price}>${props.item.org_price}</span></p>
                    </div>
                    {view_product}
                </div>
            </div>
        </div>
    );
}

export default StoreItem;