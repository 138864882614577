import { 
  createBrowserRouter, 
  RouterProvider,
} from 'react-router-dom';

import RootLayout from './pages/Root';
import HomePage from './pages/Home';
import Services from './pages/Services';
import ForSalePage, { loader as productLoader } from './pages/ForSalePage';
import ProductDetailPage from './pages/ProductDetails';
import Testimonials from './pages/Testimonials';
import Warranty from './pages/WarrantyPage';
import Error from './pages/Error';

const route = createBrowserRouter([
  {
    path: '/', 
    element: <RootLayout />, 
    errorElement: <Error />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'services', element: <Services />},
      { 
        path: 'for-sale', 
        element: <ForSalePage />, 
        loader: productLoader,
      },
      { path: 'for-sale/:id', element: <ProductDetailPage /> },
      { path: 'testimonials', element: <Testimonials /> },
      { path: 'warranty', element: <Warranty />}
    ]
  },
]);

function App() {
  return <RouterProvider router={route} />
};

export default App;
