import { useState } from 'react';

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdCircle } from "react-icons/md";
import CarouselItem from './CarouselItem';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import classes from './Carousel.module.css';
import img1 from '../../static/carousel-img-1.jpeg';
import img2 from '../../static/carousel-img-2.jpeg';
import img3 from '../../static/carousel-img-3.jpeg';
import img4 from '../../static/carousel-img-4.jpeg';
import img5 from '../../static/carousel-img-5.jpeg';

const Carousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    
    const backHandler = () => {
        const newIndex = activeIndex - 1;
        updateIndex(newIndex);
    };

    const forwardHandler = () => {
        const newIndex = activeIndex + 1;
        updateIndex(newIndex);
    };

    const updateIndex = (newIndex) => {
        if (newIndex < 0) {
            newIndex = 4;
        } else if (newIndex >= CAROUSEL_ITEMS.length) {
            newIndex = 0;
        }

        setActiveIndex(newIndex);
    };

    const CAROUSEL_ITEMS = [
        {
            image: 'We service all foreign and domestic high and low speed pneumatic dental handpieces and ultrasonic scalers',
        },
        {
            image: 'Known for 5 star craftsmanship & fine service on equipment for over 40 years',
        },
        {
            image: 'Our practice is to service the instrument within 24 hours upon reciept',
        },
        {
            image: 'Fidelity warranties all new handpieces and new parts for 90 days from the date of invoice',
        },
        {
            image: 'Our listed prices are your total amount, including all taxes. We pay for shipping and handling with delivery confirmation both ways',
        },
    ]
    
    // Add infinite scroll.
    return (
        <div className={classes.carousel}>
            <Swiper
            modules={[Navigation, Pagination]}
            navigation
            pagination={{ clickable: true }}
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            style={{
                '--swiper-pagination-color': 'rgb(41, 155, 249)',
                '--swiper-pagination-bullet-size': '20px',
                '--swiper-navigation-sides-offset': '25px',
                '--swiper-navigation-size': '60px',
                '--swiper-navigation-color': 'rgb(41, 155, 249)',
                '--swiper-navigation-top-offset': '55%',
                '--swiper-pagination-bullet-inactive-color': 'rgb(41, 155, 249)',
            }}
            >
                <SwiperSlide><img src={img1} alt='First Slide Image'/></SwiperSlide>
                <SwiperSlide><img src={img2} alt='Second Slide Image'/></SwiperSlide>
                <SwiperSlide><img src={img3} alt='Third Slide Image'/></SwiperSlide>
                <SwiperSlide><img src={img4} alt='Fourth Slide Image'/></SwiperSlide>
                <SwiperSlide><img src={img5} alt='Fifth Slide Image'/></SwiperSlide>
            </Swiper>
                {/* <div
                    className={classes.inner}
                    style={{ transform: `translate(-${activeIndex * 100}%)`}}
                >
                    {CAROUSEL_ITEMS.map((item) => {
                        return <CarouselItem item={item} index={activeIndex} />
                    })}
                </div>
                <div className={classes.carousel_buttons}>
                    <button onClick={backHandler} className={classes.button_arrows}>
                        <span className={classes.rotate}>
                            <IoIosArrowBack />
                        </span>
                    </button>
                    <div className={classes.indicators}>
                        {CAROUSEL_ITEMS.map((item, index) => (
                            <button 
                                onClick={() => {updateIndex(index)}} 
                                className={classes.indicator_buttons}
                            >
                                <span className={`material-symbols-outlined ${index === activeIndex ? classes.indicator_symbol_active : classes.indicator_symbol}`}>
                                    <MdCircle />
                                </span>
                            </button>
                        ))}
                    </div>
                    <button onClick={forwardHandler} className={classes.button_arrows}>
                        <span className={classes.rotate}>
                            <IoIosArrowForward />
                        </span>
                    </button>
                </div> */}
            </div>
    );
};

export default Carousel;