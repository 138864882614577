import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../static/fidelity-logo.jpg';
import classes from './Nav.module.css';

const Nav = () => {
    const [navOpen, setNavOpen] = useState(false);

    const toggleMenuHandler = () => {
      setNavOpen(!navOpen);
      console.log(navOpen); 
    };
    return (
        <div className={classes.header}>
            <img src={logo} alt="Fidelity Dental Logo" className={classes.logo} />
            <nav className={`${classes.links} ${navOpen ? classes.open : classes.hide}`}>
              <NavLink to='/' className={({isActive}) => isActive ? classes.active : classes.pending }>
                Home
              </NavLink>
              <NavLink to='/services' className={({isActive}) => isActive ? classes.active : classes.pending }>
                Services
              </NavLink>
              <NavLink to='/testimonials' className={({isActive}) => isActive ? classes.active : classes.pending }>
                Testimonials
              </NavLink>
              <NavLink to='/warranty' className={({isActive}) => isActive ? classes.active : classes.pending }>
                Warranty
              </NavLink>
            </nav>
        </div>
    )
}


export default Nav;