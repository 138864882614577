import { useState } from 'react';

const useValidation = (validateLogic) => {
    // This is fucking awesome! We are defining multiple state variables with the same line.
    const [value, setValue] = useState('');
    const [inputFocused, setInputFocused] = useState(false);
    
    // Sets the input value as state for whichever object is using the hook.
    const inputValueHandler = (event) => {
        setValue(event.target.value);
    };
    
    // If the input has been clicked or tabbed to.
    const inputBlurHander = () => {
        setInputFocused(true);
    };

    /* Validation logic is executed in order form, but called here 
    and an error is thrown if the input has, is, or was focused */
    let inputIsValid = validateLogic(value);
    let inputHasError = !inputIsValid && inputFocused;

    const inputReset = () => {
        setValue('');
        setInputFocused(false);
        console.log('form reset');
    };

    return {
        value: value,
        inputIsValid: inputIsValid,
        inputHasError: inputHasError,
        inputValueHandler: inputValueHandler,
        inputBlurHandler: inputBlurHander,
        inputReset: inputReset,
    }
};

export default useValidation;