import classes from './HighSpeeds.module.css';
import StoreItem from './StoreItem'; 

const HighSpeeds = (props) => {

    return (
        <>
            <h1 className={classes.hs_title}>Pneumatic High Speeds</h1>
            <div className={classes.high_speeds}>
                {props.hsProductData.map((item) => (
                    <StoreItem key={item.id} item={item} viewProduct={props.onAppear} />
                ))}
            </div>
        </>
    )
};

export default HighSpeeds;



