import Header from '../components/navigation/Nav';

const Error = () => {
    return (
        <>
            <Header />
            <h1 className='content'>Could not find this page! &#128542;</h1>
        </>
    )
};

export default Error;