import Warranty from '../components/warranty/Warranty';

const WarrantyPage = () => {
    return (
        <div>
            <Warranty />
        </div>
    )
};

export default WarrantyPage;