import { signInWithEmailAndPassword } from 'firebase/auth';
import { firebase } from './firebase';

export const Access = () => {
    signInWithEmailAndPassword(firebase.auth, firebase.cred, firebase.ential)
    .then((userCredential) => {
        return userCredential
    })
    .catch((error) => {
        return error
    });
};