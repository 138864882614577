import { useParams, Link } from 'react-router-dom';

const ProductDetailsPage = () => {
    const params = useParams();
    console.log(params)

    return (
        <div className="content">
            <h1>Product Details</h1>
            <p>{params.id}</p>
            <Link to='..' relative='path'>Back</Link>
        </div>
    );
}

export default ProductDetailsPage

